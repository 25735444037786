import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import InputText from '../../../../general/InputText';
import {REPORT_INIT} from '../ReportEditor';
import {getPartByObjectNavigation} from '../../../../../lib/project';
import {ReportDefinition, ReportTable} from '../../../../../constants/propTypesDefinitions';
import { Trans } from '@lingui/macro';
import Select from '../../../../general/Select';

/**
 * @fero
 */

const rootTableNavigation = ['root'];
const nameNavigation = ['name'];

class ReportTableSelect extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        tables: PropTypes.arrayOf(ReportTable.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
        setReport: PropTypes.func.isRequired,
    };

    setCurrentRootTable = (reportTable) => {
        const {setReport, report} = this.props;
        const name = getPartByObjectNavigation(report, nameNavigation);
        const newReport = update(REPORT_INIT, {
            name: {$set: name},
            root: {$set: reportTable}
        });
        setReport(newReport);
    };

    setReportName = (name) => {
        const {updateReport} = this.props;
        updateReport({$set: name}, nameNavigation);
    };

    render() {
        const {tables = [], report = {}} = this.props;
        const root = getPartByObjectNavigation(report, rootTableNavigation);
        const name = getPartByObjectNavigation(report, nameNavigation);
        return <div className="report-editor-part-wrapper">
            <div className="d-flex align-items-center">
                <h3 className="mr-2 flex-item-static text-dark">
                    <Trans>Názov reportu:</Trans>
                </h3>
                <InputText
                    onChange={this.setReportName}
                    value={name}
                    required={true}
                />
            </div>
            <div className="d-flex align-items-center">
                <h3 className="mr-2 flex-item-static text-dark">
                    <Trans>Report pre dáta:</Trans>
                </h3>
                <div className="flex-item-dynamic-1">
                    <Select
                        onChange={this.setCurrentRootTable}
                        options={tables.map(table => {
                            return {
                                label: table.label,
                                value: table.name,
                            }
                        })}
                        value={root}
                        required={true}
                    />
                </div>
            </div>
        </div>;
    }

}

export default ReportTableSelect;
import React from 'react';
import PropTypes from 'prop-types';
import ReportDefinitionFormulaInput from './ReportDefinitionFormulaInput';
import {getPartByObjectNavigation} from '../../../../../../lib/project';
import {OPERATION_KEY} from '../../ReportEditor';
import {Button} from 'antd'
import {ReportDefinition, ReportOperation, ReportColumn} from '../../../../../../constants/propTypesDefinitions';
import {Trans} from '@lingui/macro';

/**
 * @fero
 */

class ReportDefinitionPostFilters extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        operations: PropTypes.arrayOf(ReportOperation.isRequired).isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
    };

    addFilter = () => {
        const {navigation, updateReport} = this.props;
        const updateDef = {
            $set: {
                [OPERATION_KEY]: null,
            }
        };
        updateReport(updateDef, navigation);
    };

    removeFilter = () => {
        const {navigation, updateReport} = this.props;
        const updateDef = {
            $set: null,
        };
        updateReport(updateDef, navigation);
    };

    render() {
        const {operations, report, updateReport, navigation} = this.props;
        const filter = getPartByObjectNavigation(report, navigation);
        const operationsFiltered = operations.filter(op => !op.is_aggregate);
        const reportColumns = getPartByObjectNavigation(report, ['columns']);
        const reportColumnsWithNames = reportColumns.map(col => { return {name: col.label, label: col.label}; });
        return <div className="report-definition-part-wrapper">
            <h3>
                <Trans>Výstupný filter:</Trans>
            </h3>
            { filter != null ? 
                <ReportDefinitionFormulaInput
                    report={report}
                    operations={operationsFiltered}
                    columns={reportColumnsWithNames}
                    updateReport={updateReport}
                    navigation={navigation}
                    onDelete={this.removeFilter}
                /> 
                : 
                <Button onClick={this.addFilter}>
                    <Trans>Pridať</Trans>
                </Button>
            }
        </div>;
    }

}

export default ReportDefinitionPostFilters;
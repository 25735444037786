import React from 'react';
import PropTypes from 'prop-types'
import Select from '../../../../../general/Select';
import ReportDefinitionColumnSelect from './ReportDefinitionColumnSelect';
import {getPartByObjectNavigation} from '../../../../../../lib/project';
import {Button} from 'antd'
import {ReportDefinition, ReportColumn} from '../../../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';


/**
 * @fero
 */

const ASC_KEY = 'asc';
const DESC_KEY = 'desc';
const DIRECTIONS = {
    [ASC_KEY]: {
        key: ASC_KEY,
        label: <Trans>Vzostupne</Trans>
    },
    [DESC_KEY]: {
        key: DESC_KEY,
        label: <Trans>Zostupne</Trans>
    },
};

const ORDER_BY_VALUE_SEPARATOR = ' ';

class ReportDefinitionOrderBy extends React.PureComponent {
    static propTypes = {
        report: ReportDefinition.isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        navigation: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        updateReport: PropTypes.func.isRequired,
    };

    addOrderByColumn = () => {
        const {navigation, updateReport} = this.props;
        const updateDef = {
            $push: [null]
        };
        updateReport(updateDef, navigation);
    };

    removeOrderByColumn = (index) => {
        const {navigation, updateReport} = this.props;
        const updateDef = {
            $splice: [[index, 1]]
        };
        updateReport(updateDef, navigation);
    };

    updateOrderByColumn = (columnName, direction, navigation) => {
        const {report, updateReport} = this.props;
        const orderByValue = getPartByObjectNavigation(report, navigation);
        const currentColumnsName = this.getColumnNameFromOrderByValue(orderByValue);
        const currentDirection = this.getDirectionFromOrderByValue(orderByValue);
        const currentDirectionChecked = currentDirection != null ? currentDirection : ASC_KEY;
        const newColumnName = columnName != null ? columnName : currentColumnsName;
        const newDirection = direction != null ? direction : currentDirectionChecked;
        const newValue = newColumnName + ORDER_BY_VALUE_SEPARATOR + newDirection;
        const updateDef = {
            $set: newValue,
        };
        updateReport(updateDef, navigation);
    };

    getColumnNameFromOrderByValue = (value) => {
        if (value == null) {
            return null;
        } else {
            const parts = value.split(ORDER_BY_VALUE_SEPARATOR);
            return parts[0];
        }
    };

    getDirectionFromOrderByValue = (value) => {
        if (value == null) {
            return null;
        } else {
            const parts = value.split(ORDER_BY_VALUE_SEPARATOR);
            return parts[1];
        }
    };

    render() {
        const {columns, report, navigation} = this.props;

        const orderByColumns = getPartByObjectNavigation(report, navigation);
        const orderByColumnsChecked = orderByColumns != null ? orderByColumns : [];

        return <div className="report-definition-part-wrapper">
            <h3>
                <Trans>Usporiadať podľa:</Trans>
            </h3>
            {orderByColumnsChecked.map((orderByColumn, index) => {
                let newNavigation = [...navigation];
                newNavigation.push((index + ''));
                const orderByValue = getPartByObjectNavigation(report, newNavigation);
                const columnName = this.getColumnNameFromOrderByValue(orderByValue);
                const direction = this.getDirectionFromOrderByValue(orderByValue);
                return <ReportDefinitionColumnSelect
                    key={index}
                    columns={columns}
                    onChange={(columnN) => {
                        this.updateOrderByColumn(columnN, null, newNavigation)
                    }}
                    onDelete={() => {
                        this.removeOrderByColumn(index)
                    }}
                    selectedColumnName={columnName}
                    after={<OrientationSelector
                        onChange={(direction) => {
                            this.updateOrderByColumn(null, direction, newNavigation)
                        }}
                        value={direction}
                    />}
                />;
            })}
            <Button
                onClick={this.addOrderByColumn}
            >
                <Trans>Pridať</Trans>
            </Button>
        </div>;
    }

}

export default ReportDefinitionOrderBy;

class OrientationSelector extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {value, onChange} = this.props;
        return <Select
            isSearchable={false}
            className="report-type-input d-inline-block my-1"
            onChange={onChange}
            options={Object.values(DIRECTIONS).map(direction => {
                return {
                    label: direction.label,
                    value: direction.key,
                }
            })}
            value={value}
        />;
    }
}
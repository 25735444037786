import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ReportTableSelect from './reportEditor/ReportTableSelect';
import ReportDefinition from './reportEditor/ReportDefinition';
import {ReportDefinition as ReportDefinitionProp, ReportColumn, ReportOperation, ReportTable} from '../../../../constants/propTypesDefinitions';
import {Trans} from '@lingui/macro';
import { Button, notification } from 'antd';
import { createFetchReportAdd, createFetchReportEdit } from '../../../../backend/apiCalls';
import withDataHOC from '../../../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../../../constants/globalData';

/**
 * @fero
 */

export const COLUMN_KEY = 'col';
export const OPERATION_KEY = 'operation';
export const VALUE_KEY = 'val';
export const INPUTS_KEY = 'inputs';
export const TYPES = {
    [COLUMN_KEY]: {
        label: <Trans render={null}>Položka</Trans>,
        key: COLUMN_KEY,
    },
    [OPERATION_KEY]: {
        label: <Trans render={null}>Operácia</Trans>,
        key: OPERATION_KEY,
    },
    [VALUE_KEY]: {
        label: <Trans render={null}>Hodnota</Trans>,
        key: VALUE_KEY,
    }
};

export const REPORT_INIT = {
    name: null,
    root: null,
    columns: [{formula: {[COLUMN_KEY]: null}}],
    filter: null,
    param: null,
    key_columns: [],
    order_columns: [],
    args: null,
    show_summary: 0,
};

class ReportEditor extends React.PureComponent {
    static propTypes = {
        report: ReportDefinitionProp,
        action: PropTypes.string,
        onReportAdd: PropTypes.func.isRequired,
        onReportEdit: PropTypes.func.isRequired,
        reloadColumns: PropTypes.func.isRequired,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        operations: PropTypes.arrayOf(ReportOperation.isRequired).isRequired,
        tables: PropTypes.arrayOf(ReportTable.isRequired).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            tmpReport: props.report != null ? this.setEditorDefaultsToExistingReport(props.report) : REPORT_INIT,
            loading: false,
        };
    }

    componentDidMount() {
        const {reloadColumns} = this.props;
        const {tmpReport} = this.state;
        const {root} = tmpReport;
        if (root != null) {
            reloadColumns(root);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {report, reloadColumns} = this.props;
        const {report: prevReport}  = prevProps;
        if (report != prevReport)
        {
            if (report != null && report.root != null) {
                this.setState(
                    {
                        tmpReport: this.setEditorDefaultsToExistingReport(report)
                    }, 
                    () => {
                        reloadColumns(this.state.tmpReport.root)
                    }
                );
            }
            else 
            {
                this.setState({
                    tmpReport: REPORT_INIT,
                });
            }
        } 
        else 
        {
            const {tmpReport} = this.state;
            const {tmpReport: prevtmpReport = {}} = prevState;
            const {root} = tmpReport;
            const {root: prevRoot} = prevtmpReport;
            if (root != null && root != prevRoot)
                reloadColumns(root);
        }
        
    }

    setEditorDefaultsToExistingReport = (report) => {
        let updateObj = {};
        if (report.columns == null) {
            updateObj.columns = {$set: []};
        }
        if (report.key_columns == null) {
            updateObj.key_columns = {$set: []};
        }
        if (report.order_columns == null) {
            updateObj.order_columns = {$set: []};
        }
        return update(report, updateObj);
    };

    updateReport = (updateDef, navigation) => {
        const {tmpReport} = this.state;
        let updateObj = updateDef;
        if (navigation.length != null && navigation.length > 0 && !Number.isNaN(Number(navigation.length))) {
            let i = (navigation.length - 1);
            while (i >= 0) {
                let tmp = {};
                tmp[navigation[i]] = updateObj;
                updateObj = tmp;
                i--;
            }
        }
        const updatedReport = update(tmpReport, updateObj);
        this.setState({tmpReport: updatedReport});
    };

    setReport = (report) => {
        this.setState({tmpReport: report});
    };

    handleSubmit = (ev) => {
        ev.preventDefault();
        const {report, action, onReportAdd, onReportEdit, [GLOBAL_DATA.FETCH_DATA]: fetchData} = this.props;
        const {tmpReport} = this.state;
        const reportId = report != null ? report.id : null;
        const isEdit =  (reportId != null && action != 'copy');
        const fetchFunc = isEdit ? 
            createFetchReportEdit(reportId, tmpReport)
            : createFetchReportAdd(tmpReport);

        const onFinish = isEdit ? onReportEdit : onReportAdd;
        this.setState({loading: true});
        fetchData(
            fetchFunc,
            {},
            onFinish,
            () => { this.setState({loading: false}) },
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    }

    render() {
        const {tables, operations, columns} = this.props;
        const {tmpReport, loading} = this.state;
        
        return <div className="overflow-auto flex-item-dynamic-1">
        <form onSubmit={this.handleSubmit}>
            <ReportTableSelect
                tables={tables}
                updateReport={this.updateReport}
                setReport={this.setReport}
                report={tmpReport}
            />
            {tmpReport.root != null ? <ReportDefinition
                updateReport={this.updateReport}
                report={tmpReport}
                operations={operations}
                columns={columns}
            /> : null}
            <div className="report-editor-part-wrapper d-flex align-items-center justify-content-center">
                <Button type="primary" htmlType="submit" loading={loading}>
                    <Trans>Uložiť</Trans>
                </Button>
            </div>
        </form>
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.FETCH_DATA])(ReportEditor);